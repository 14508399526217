#home {
	/*background-image:url("../img/pictures/warehouse_small.png");*/
	background-size: cover;
	height:261px;
	background-position: bottom;
}

.hero{
	/*background-image:url("../img/pictures/user_2.jpg");*/
	background-size: cover;
	height:261px;
	background-position: 0px 55%;
}

.nav-justified>li>a{
	color:#334d5c;
	font-size:30px;
	font-weight:bolder;
}

.nav-justified>li>a:hover {
	color:#269abc;
	background-color: transparent;
}

.featurette-divider {
	margin: 80px 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
	font-weight: 800;
	font-size:40px;
	line-height: 1;
	letter-spacing: -1px;
}

.odd  {
	background-color:#ededed;
}

.featurette {
	padding-top:48px;
	padding-bottom:48px;
}