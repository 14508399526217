/**
* Layout
 */
html {
    font-family: serif;
}

body {
    font-family: serif;
}

.dataTable_wrapper {
    font-family: serif;
}

/* Flash Messages */
#flashWrapper {
    position: absolute;
    left: 50%;
    margin-left: -200px;
    width: 400px;
    top: 0px;
}

/* Horizontal menu */
.menu_horizontal {
    background: #fefefe; /* Old browsers */
    background: -moz-linear-gradient(top, #fefefe 0%, #fefefe 37%, #e3e3e3 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #fefefe 0%, #fefefe 37%, #e3e3e3 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #fefefe 0%, #fefefe 37%, #e3e3e3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fefefe', endColorstr='#e3e3e3', GradientType=0); /* IE6-9 */

}

.barTitle {
    font-size: 26px;
}

.image_wrapper {
    width: 100%;
    height: 185px;
    padding: 0px;
}

.description_wrapper {
    height: 32px;
}

.productImage {
    max-width: 100%;
    max-height: 180px;
}

.addToCartBtn a {
    width: 75px;
}

.ui-autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    display: none;
    min-width: 160px;
    _width: 160px;
    padding: 4px 0;
    margin: 2px 0 0 0;
    list-style: none;
    background-color: #ffffff;
    border-color: #ccc;
    border-color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-width: 1px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    *border-right-width: 2px;
    *border-bottom-width: 2px;

}

.ui-menu-item > a.ui-corner-all {
    display: block;
    padding: 3px 15px;
    clear: both;
    font-weight: normal;
    line-height: 18px;
    color: #555555;
    white-space: nowrap;
}

.ui-state-hover,
.ui-state-active {
    color: #ffffff;
    text-decoration: none;
    background-color: #0088cc;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    background-image: none;
}

/**
* Compatibility between jquery-ui autocomplete and bootstrap modals
*/
.ui-autocomplete {
    position: absolute;
    cursor: default;
    z-index: 2000 !important;
}

.wrap {
    white-space: pre-wrap; /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* IE */
}

.break {
    word-wrap: break-word;
    word-break: break-all;
}

.ui-datepicker-month {
    color: #333;
}

.ui-datepicker-year {
    color: #333;
}

.price {
    color: #9E2424;
    font-weight: lighter;
    font-size: 18px;
}

/**
* Allows for a table where the tr's are rendered on a single continuous line
*/
.table_inline {
    display: inline;
}

.tr_inline {
    display: inline;
    float: left;
}

.td_inline {
}

/**
* /companies/dashboard
*/
.dashboard-text {
    height: 100px;
}

/**
* Order Product Modal
 */
#g_orderProduct_productName {
    font-size: 20px;
}

/**
* Print compatability
*/
@media print {
    nav,
    .no-print,
    .navbar-header,
    .sidebar-nav,
    .nav,
    #side-menu,
    .navbar {
        display: none !important;
    }
}


/**
* React Autosuggest
*/
.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-height: 200px;
    overflow-y: auto;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.form-group > label {
    color: #6c757d;
    font-size: 0.9rem;
    margin-bottom: 0px;
}

