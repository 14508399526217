.productsTable {

}


/*
* 
*/
.productsTable .productWrapper {
    width: 200px;
}

.productsTable .productDescriptionWrapper {

}

.productsTable .priceWrapper {
    border-top: solid 1px #acacac;
}

.productsTable .imageWrapper {
    height:200px;
}

.productsTable .productImage {
    max-width: 180px;
    max-height: 180px;
    width:auto;
    height:auto;
}

/*
* The name of the product in the product table 
*/
.productsTable .productNameWrapper {
    height: 48px;
}

.productsTable .productName {
    color:black;
}



/*
* Price of the product inside a product table 
*/
.productsTable .productPrice {

}
